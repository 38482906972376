import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ id }) => {
  return (
    // <div className="sports_product_item">
    //   <div className="item_image" data-bg-color="#f5f5f5">
    //     <img src="assets/images/shop/sports/img_03.png" alt="image_not_found" />
    //     <ul className="product_action_btns ul_li_center clearfix">
    //       <li>
    //         <Link to="/product?pid=0">
    //           <i className="fas fa-shopping-cart" />
    //         </Link>
    //       </li>
    //     </ul>
    //     <ul className="product_label ul_li text-uppercase clearfix">
    //       <li className="bg_sports_red">50% Off</li>
    //       <li className="bg_sports_red">Sale</li>
    //     </ul>
    //   </div>
    //   <div className="item_content text-uppercase text-white">
    //     <h3 className="item_title bg_black text-white mb-0">
    //       PHANTOM VISION ACADEMY
    //     </h3>
    //     <span className="item_price bg_sports_red">
    //       <strong>₹195</strong> <del>₹390</del>
    //     </span>
    //   </div>
    // </div>
    <div className="card product-card border-0 shadow">
      <div className="card-body position-relative">
        <div className="offer-sticky">
          <p className="m-0">50% off</p>
        </div>
        <button className="wishlist-sticky">
          <i class="far fa-heart"></i>
        </button>
        <div className="product-card-img-wrapper">
          <div
            id={`product-card-images-card${id}`}
            className="carousel slide"
            data-ride="carousel"
            data-pause="false"
          >
            <ol className="carousel-indicators">
              <li
                data-target={`#product-card-images-card${id}`}
                data-slide-to={0}
                className="active"
              />
              <li
                data-target={`#product-card-images-card${id}`}
                data-slide-to={1}
              />
              <li
                data-target={`#product-card-images-card${id}`}
                data-slide-to={2}
              />
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <Link to={"/product"} className="product-carosuel-img">
                  <img
                    src="assets/images/shop/sports/img_03.png"
                    className="d-block "
                    alt="productimg"
                  />
                </Link>
              </div>
              <div className="carousel-item">
                <Link className="product-carosuel-img" to={"/product"}>
                  <img
                    src="assets/images/shop/sports/img_03.png"
                    className="d-block "
                    alt="productimg"
                  />{" "}
                </Link>
              </div>
              <div className="carousel-item">
                <Link to={"/product"} className="product-carosuel-img">
                  <img
                    src="assets/images/shop/sports/img_03.png"
                    className="d-block "
                    alt="productimg"
                  />{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Link to={"/product"} className="product-card-details w-100">
          <p className="product-card-name">Men's Tshirt Black color</p>
          <div className="product-price-container">
            <div className="d-flex justify-content-between align-items-center">
              <div className="price-box">
                <span className="discounted-price">₹300</span>
                <span className="actual-price">
                  <small>
                    <strike>₹400</strike>
                  </small>
                </span>
                <div className="product-card-rating">
                  <div className="d-flex">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star-half" />
                  </div>
                </div>
              </div>
              <div className="product-card-addtobag">
                <button className="addtobag-icon">
                  <i class="fal fa-shopping-bag"></i>
                </button>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
