import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import ProductCard from "../components/ProductCard";
import ImageSlider from "../components/Product/ImageSlider";
import useScrollTo from "../components/useScrollTo";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Productpage = () => {
  const images = [
    { id: "di_tab_1", src: "assets/images/shop/sports/img_03.png" },
    { id: "di_tab_2", src: "assets/images/feature/sports/img_03.jpg" },
    { id: "di_tab_3", src: "assets/images/shop/sports/img_03.png" },
    { id: "di_tab_4", src: "assets/images/feature/sports/img_03.jpg" },
    { id: "di_tab_5", src: "assets/images/feature/sports/img_03.jpg" },
    { id: "di_tab_6", src: "assets/images/feature/sports/img_03.jpg" },
    { id: "di_tab_7", src: "assets/images/feature/sports/img_03.jpg" },
    { id: "di_tab_8", src: "assets/images/feature/sports/img_03.jpg" },
  ];
  const settings4 = {
    dots: false,
    speed: 1000,
    arrows: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    pauseOnHover: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 551,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const selector = "product-details";
  useScrollTo(selector);
  //   const { pid } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pid = searchParams.get("pid");
  const breadlinks = [
    {
      url: "/",
      text: "Home",
    },
    {
      url: "/productslist?cid=0",
      text: "Category",
    },
    {
      url: "/product",
      text: "Product",
    },
  ];
  return (
    <div>
      {" "}
      <div>
        <Header />
        {/* <ScrollToTop /> */}
        <div className="productpage-main">
          <Breadcrumb
            pagename={"Product"}
            breadcrumbitems={breadlinks}
            backgroundimg={"assets/images/breadcrumb/bg_14.jpg"}
          />

          <div id="product-details">
            {/* sports_details - start
			================================================== */}
            <section className="sports_details product-page-section sec_ptb_100 pb-5 mb-5 clearfix">
              <div className="container">
                <div className="row justify-content-lg-between">
                  <div className="col-lg-12">
                    <div className="row mb_100 bg-black justify-content-lg-between">
                      <div className="col-lg-6 col-md-6 d-md-block d-none">
                        <ImageSlider />
                      </div>
                      <div className="d-md-none d-block col-12 px-0 mob-product-slider">
                        <div className="mobile-product-apge-slider">
                          <Slider {...settings4}>
                            {images.map((image, index) => (
                              <div
                                key={index}
                                className="mob-product-slider-item"
                              >
                                <img src={image.src} alt="img" />
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                      {/* right section */}
                      <div className="col-lg-6 col-md-6 product-page-details-wrapper">
                        <div className="details_content">
                          {/* category */}
                          <span className="item_type">Tshirts</span>
                          <h2 className="item_title mb_15">Men's Polo Bag</h2>
                          {/* rating */}
                          <div className="rating_wrap d-flex align-items-center mb_15 text-uppercase">
                            <ul className="rating_star ul_li mr-2 clearfix">
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                            </ul>
                            <span
                              className="review_text"
                              data-text-color="#ff3f3f"
                            >
                              (5.0 Rating)
                            </span>
                          </div>
                          {/* small description */}
                          <p className="mb_15">
                            Investigationes demonstraverunt lectores legere me
                            lius quod ii legunt saepius est etiam processus
                            dynamicus qui.
                          </p>
                          <span className="product_price mb_30">
                            {/* /actual and discounted price */}
                            <del>₹99.99</del> <strong>₹69.00</strong>
                          </span>
                          {/* size */}
                          <div className="item_size d-flex align-items-center mb_30">
                            <h4 className="list_title text-uppercase mb-0 mr-3">
                              Size:
                            </h4>
                            <ul className="ul_li clearfix">
                              <li>
                                <button type="button">XS</button>
                              </li>
                              <li>
                                <button type="button">X</button>
                              </li>
                              <li>
                                <button type="button" className="active">
                                  M
                                </button>
                              </li>
                              <li>
                                <button type="button">L</button>
                              </li>
                              <li>
                                <button type="button">XL</button>
                              </li>
                            </ul>
                          </div>
                          <ul className="btns_group ul_li clearfix">
                            <li>
                              <div className="quantity_input">
                                <form action="#">
                                  <span className="input_number_decrement">
                                    –
                                  </span>
                                  <input
                                    className="input_number"
                                    type="text"
                                    defaultValue={1}
                                  />
                                  <span className="input_number_increment">
                                    +
                                  </span>
                                </form>
                              </div>
                            </li>
                            <li>
                              <a
                                className="custom_btn bg_sports_red text-uppercase"
                                href="#!"
                              >
                                Add To Cart
                              </a>
                            </li>
                          </ul>
                          <hr />
                          <div className="item_tags mb_15 d-flex align-items-center">
                            <h4 className="list_title text-uppercase mb-0 mr-3">
                              Tags:
                            </h4>
                            <ul className="ul_li clearfix">
                              <li>
                                <a href="#!">T-Shirt</a>
                              </li>
                              <li>
                                <a href="#!">Clothes</a>
                              </li>
                              <li>
                                <a href="#!">Fashion</a>
                              </li>
                              <li>
                                <a href="#!">Shop</a>
                              </li>
                            </ul>
                          </div>
                          {/* <div className="share_links d-flex align-items-center">
                            <h4 className="list_title text-uppercase mb-0 mr-3">
                              Share:
                            </h4>
                            <ul className="primary_social_links ul_li_right clearfix">
                              <li>
                                <a href="#!">
                                  <i className="fab fa-facebook-f" />
                                </a>
                              </li>
                              <li>
                                <a href="#!">
                                  <i className="fab fa-twitter" />
                                </a>
                              </li>
                              <li>
                                <a href="#!">
                                  <i className="fab fa-instagram" />
                                </a>
                              </li>
                              <li>
                                <a href="#!">
                                  <i className="fab fa-whatsapp" />
                                </a>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="sports_description_tab mb_100">
                      <ul
                        className="nav justify-content-md-center justify-content-around text-uppercase"
                        role="tablist"
                      >
                        <li>
                          <a
                            className="active "
                            data-toggle="tab"
                            href="#description_tab"
                          >
                            Description
                          </a>
                        </li>
                        {/* <li>
                          <a data-toggle="tab" href="#information_tab">
                            Additional Information
                          </a>
                        </li> */}
                        <li>
                          <a data-toggle="tab" className="" href="#review_tab">
                            Reviews (3)
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div id="description_tab" className="tab-pane active">
                          <h3 className="title_text mb_15">Description:</h3>
                          <p className="mb_15">
                            Investigationes demonstraverunt lectores legere me
                            lius quod ii legunt saepius. Claritas est etiam
                            processus dynamicus, qui sequitur mutationem
                            consuetudium lectorum. Mirum est notare quam littera
                            gothica, quam nunc putamus parum claram,
                            anteposuerit litterarum formas humanitatis per
                            seacula quarta decima et quinta decima. Eodem modo
                            typi, qui nunc nobis videntur parum clari, fiant
                            sollemnes in futurum.
                          </p>
                          <p className="mb_15">
                            Investigationes demonstraverunt lectores legere me
                            lius quod ii legunt saepius. Claritas est etiam
                            processus dynamicus, qui sequitur mutationem
                            consuetudium lectorum.
                          </p>
                          <p className="mb-0">
                            Eodem modo typi, qui nunc nobis videntur parum
                            clari.
                          </p>
                          <div className="row">
                            <div className="col-lg-5">
                              <ul className="des_info_list ul_li_block">
                                <li>
                                  <i className="fas fa-caret-right" />
                                  Nam liber tempor cum;
                                </li>
                                <li>
                                  <i className="fas fa-caret-right" />
                                  Mirum est notare quam;
                                </li>
                                <li>
                                  <i className="fas fa-caret-right" />
                                  Claritas est etiam;
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-5">
                              <ul className="des_info_list ul_li_block">
                                <li>
                                  <i className="fas fa-caret-right" />
                                  Typi non habent claritatem;
                                </li>
                                <li>
                                  <i className="fas fa-caret-right" />
                                  Eodem modo typi.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div id="review_tab" className="tab-pane fade">
                          <form action="#">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="form_item">
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="form_item">
                                  <input
                                    type="email"
                                    name="email"
                                    placeholder="Your Email"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form_item">
                                  <input
                                    type="text"
                                    name="subject"
                                    placeholder="Subject"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form_item">
                              <textarea
                                name="message"
                                placeholder="Your Message"
                                defaultValue={""}
                              />
                            </div>
                            <button
                              type="submit"
                              className="custom_btn bg_default_red text-uppercase"
                            >
                              Submit Review
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* related products */}
                    <div className="sports_related_products">
                      <div className="sports_section_title text-uppercase">
                        <span className="sub_title mb-0">Join Our</span>
                        <h3 className="title_text mb-0">Related Products</h3>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                          <ProductCard id={1} />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                          <ProductCard id={1} />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                          <ProductCard id={1} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 d-none">
                    <aside className="sports_sidebar sidebar_section">
                      <div className="sb_widget sb_search">
                        <h3 className="sb_widget_title text-uppercase">
                          Search
                        </h3>
                        <form action="#">
                          <div className="form_item mb-0">
                            <input
                              type="search"
                              name="search"
                              placeholder="Type and hit Enter..."
                            />
                          </div>
                        </form>
                      </div>
                      <div className="sb_widget sb_category">
                        <h3 className="sb_widget_title text-uppercase">
                          Categories
                        </h3>
                        <ul className="ul_li_block clearfix">
                          <li>
                            <a href="#!">
                              <i className="fas fa-caret-right mr-2" />{" "}
                              Accessories <span>(68)</span>
                            </a>
                          </li>
                          <li>
                            <a href="#!">
                              <i className="fas fa-caret-right mr-2" /> Clothing{" "}
                              <span>(36)</span>
                            </a>
                          </li>
                          <li>
                            <a href="#!">
                              <i className="fas fa-caret-right mr-2" /> Things{" "}
                              <span>(29)</span>
                            </a>
                          </li>
                          <li>
                            <a href="#!">
                              <i className="fas fa-caret-right mr-2" />{" "}
                              Polygraphy <span>(20)</span>
                            </a>
                          </li>
                          <li>
                            <a href="#!">
                              <i className="fas fa-caret-right mr-2" /> Other{" "}
                              <span>(7)</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* <div className="sb_widget sb_pricing_range">
                        <h3 className="sb_widget_title text-uppercase">
                          Filter by Price:
                        </h3>
                        <form action="#">
                          <div className="price-range-area clearfix">
                            <div id="slider-range" className="slider-range" />
                            <div className="price-text d-flex mb_15 align-items-center">
                              <span>Price:</span>
                              <input type="text" id="amount" readOnly />
                            </div>
                            <button
                              type="button"
                              className="price_filter_btn text-uppercase"
                            >
                              Filter
                            </button>
                          </div>
                        </form>
                      </div> */}
                      <div className="sb_widget sb_recent_post">
                        <h3 className="sb_widget_title text-uppercase">
                          Popular Items
                        </h3>
                        <div className="carparts_small_blog">
                          <a className="item_image" href="blog_details.html">
                            <img
                              src="assets/images/shop/sports/img_03.png"
                              alt="image_not_found"
                            />
                          </a>
                          <div className="item_content">
                            <ul className="rating_star ul_li clearfix">
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fal fa-star" />
                              </li>
                            </ul>
                            <h3 className="item_title text-uppercase">
                              <a href="blog_details.html">earphone case</a>
                            </h3>
                            <div className="item_price">
                              <strong data-text-color="#39b54a">₹29.90</strong>{" "}
                              <del>₹48.90</del>
                            </div>
                          </div>
                        </div>
                        <div className="carparts_small_blog">
                          <a className="item_image" href="blog_details.html">
                            <img
                              src="assets/images/shop/sports/img_03.png"
                              alt="image_not_found"
                            />
                          </a>
                          <div className="item_content">
                            <ul className="rating_star ul_li clearfix">
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fal fa-star" />
                              </li>
                            </ul>
                            <h3 className="item_title text-uppercase">
                              <a href="blog_details.html">earphone case</a>
                            </h3>
                            <div className="item_price">
                              <strong data-text-color="#39b54a">₹29.90</strong>{" "}
                              <del>₹48.90</del>
                            </div>
                          </div>
                        </div>
                        <div className="carparts_small_blog">
                          <a className="item_image" href="blog_details.html">
                            <img
                              src="assets/images/shop/sports/img_03.png"
                              alt="image_not_found"
                            />
                          </a>
                          <div className="item_content">
                            <ul className="rating_star ul_li clearfix">
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fal fa-star" />
                              </li>
                            </ul>
                            <h3 className="item_title text-uppercase">
                              <a href="blog_details.html">earphone case</a>
                            </h3>
                            <div className="item_price">
                              <strong data-text-color="#39b54a">₹29.90</strong>{" "}
                              <del>₹48.90</del>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="sb_widget sb_newsletter">
                        <h3 className="sb_widget_title text-uppercase">
                          Search
                        </h3>
                        <form action="#">
                          <div className="form_item mb-0">
                            <input
                              type="email"
                              name="email"
                              placeholder="Email Address"
                            />
                            <button type="submit" className="submit_btn">
                              <img
                                src="assets/images/icons/send.png"
                                alt="icon_not_found"
                              />
                            </button>
                          </div>
                        </form>
                      </div> */}
                      <div className="sb_widget sb_tags">
                        <h3 className="sb_widget_title text-uppercase">
                          Popular Tags
                        </h3>
                        <ul className="ul_li clearfix">
                          <li>
                            <a href="#!">Mock-Ups</a>
                          </li>
                          <li>
                            <a href="#!">UI</a>
                          </li>
                          <li>
                            <a href="#!">Websites</a>
                          </li>
                          <li>
                            <a href="#!">Brand</a>
                          </li>
                          <li>
                            <a href="#!">PSD Templates</a>
                          </li>
                          <li>
                            <a href="#!">WordPress</a>
                          </li>
                          <li>
                            <a href="#!">UI Kit</a>
                          </li>
                          <li>
                            <a href="#!">Graphic Design</a>
                          </li>
                        </ul>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </section>
            {/* sports_details - end
			================================================== */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Productpage;
