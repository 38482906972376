import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";
import { Link } from "react-router-dom";
import useScrollTo from "../../components/useScrollTo";

const CheckoutThree = () => {
  useScrollTo();
  const breadlinks = [
    {
      url: "/",
      text: "Home",
    },
    {
      url: "/checkout",
      text: "Checkout",
    },
  ];
  return (
    <div>
      <Header />
      <main>
        {" "}
        <Breadcrumb
          pagename={"Order Placed!"}
          breadcrumbitems={breadlinks}
          backgroundimg={"assets/images/breadcrumb/bg_14.jpg"}
        />
        <section class="checkout_section sec_ptb_140 clearfix">
          <div class="container">
            <ul class="checkout_step ul_li clearfix">
              <li className="activated">
                <Link to="/checkout">
                  <span>01.</span> Shopping Cart
                </Link>
              </li>
              <li className="activated">
                <Link to="/checkout-two">
                  <span>02.</span> Checkout
                </Link>
              </li>
              <li className="active">
                <Link to="/checkout-three">
                  <span>03.</span> Order Completed
                </Link>
              </li>
            </ul>

            <div class="order_complete_alart text-center">
              <h2>
                Congratulation! You’ve <strong>Completed</strong> Payment.
              </h2>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default CheckoutThree;
