import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { callcolorsfunction } from "./int";

const Header = () => {
  useEffect(() => {
    callcolorsfunction();
  }, []);

  const [categories, setcategories] = useState([
    "Shoes",
    "Tshirt",
    "Jeans",
    "Shirts",
    "Watches",
    "Sports Wear",
    "Suits",
    "Sunglasses",
  ]);

  const [activaclass, setactivaclass] = useState(false);
  const [activaclassoverlay, setactivaclassoverlay] = useState(false);

  const togglesidebar = () => {
    setactivaclass(true);
    setactivaclassoverlay(true);
  };

  const closetogglesidebar = () => {
    setactivaclass(false);
    setactivaclassoverlay(false);
  };

  const [cartactivaclass, setcartactivaclass] = useState(false);

  const carttogglesidebar = () => {
    setcartactivaclass(true);
    setactivaclassoverlay(true);
  };

  const closecarttogglesidebar = () => {
    setcartactivaclass(false);
    setactivaclassoverlay(false);
  };

  const closesidebar = () => {
    setactivaclass(false);
    setcartactivaclass(false);
    setactivaclassoverlay(false);
  };

  return (
    <>
      <header className="header_section sports_header sticky_header d-flex align-items-center clearfix">
        <div className="container-fluid prl_100">
          <div className="row align-items-center">
            <div className="col-6">
              <div className="brand_logo">
                <Link className="brand_link" to="/">
                  <img
                    src="assets/images/logo/logo_32_1x.png"
                    srcSet="assets/images/logo/logo_32_2x.png 2x"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
            </div>
            <div className="col-6">
              <div className="header_btns_group d-flex align-items-center justify-content-end">
                <ul className="circle_social_links ul_li clearfix">
                  <li>
                    {/* <a href="#!">
                      <i className="fab fa-facebook-f" />
                    </a> */}
                    <Link
                      to={""}
                      class="search_btn"
                      data-toggle="modal"
                      data-target="#searchmodal"
                      aria-expanded="false"
                      aria-controls="search_body_collapse"
                    >
                      <i class="fal fa-search"></i>
                    </Link>
                  </li>{" "}
                  <li>
                    <Link to="/profile">
                      <i class="fas fa-user"></i>
                    </Link>
                  </li>
                </ul>
                <ul className="header_action_btns ul_li clearfix">
                  <li>
                    <button
                      type="button"
                      onClick={carttogglesidebar}
                      className="cart_btn"
                    >
                      <i className="fas fa-shopping-cart" />
                      <span className="btn_badge">2</span>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => togglesidebar()}
                      className="mobile_menu_btn"
                    >
                      <i className="far fa-bars" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div>
        {/* Button trigger modal */}

        {/* Modal */}
        <div
          className="modal p-0 m-0 fade"
          id="searchmodal"
          tabIndex={-1}
          aria-labelledby="searchmodalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog m-0 d-flex justify-content-center align-items-center ">
            <div className="modal-content border-0 rounded-0 vh-100">
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="search-container container h-100">
                  <form className="row mx-0 justify-content-center align-items-center h-100">
                    <div className="col-12 border-bottom">
                      <input
                        type="search"
                        name="search"
                        placeholder="Type here..."
                      />
                      <button type="submit">
                        <i class="fal fa-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar-menu-wrapper">
        <div className={`cart_sidebar ${cartactivaclass ? "active" : ""}`}>
          <button
            type="button"
            onClick={closecarttogglesidebar}
            className="close_btn"
          >
            <i className="fal fa-times" />
          </button>
          <ul className="cart_items_list ul_li_block mb_30 clearfix">
            <li>
              <div className="item_image">
                <img
                  src="assets/images/cart/img_01.jpg"
                  alt="image_not_found"
                />
              </div>
              <div className="item_content">
                <h4 className="item_title">Yellow shirt</h4>
                <span className="item_price">$30.00</span>
              </div>
              <button type="button" className="remove_btn">
                <i className="fal fa-trash-alt" />
              </button>
            </li>
            <li>
              <div className="item_image">
                <img
                  src="assets/images/cart/img_01.jpg"
                  alt="image_not_found"
                />
              </div>
              <div className="item_content">
                <h4 className="item_title">Yellow shirt</h4>
                <span className="item_price">$30.00</span>
              </div>
              <button type="button" className="remove_btn">
                <i className="fal fa-trash-alt" />
              </button>
            </li>
            <li>
              <div className="item_image">
                <img
                  src="assets/images/cart/img_01.jpg"
                  alt="image_not_found"
                />
              </div>
              <div className="item_content">
                <h4 className="item_title">Yellow shirt</h4>
                <span className="item_price">$30.00</span>
              </div>
              <button type="button" className="remove_btn">
                <i className="fal fa-trash-alt" />
              </button>
            </li>
          </ul>
          <ul className="total_price ul_li_block mb_30 clearfix">
            <li>
              <span>Subtotal:</span>
              <span>$90</span>
            </li>
            <li>
              <span>Vat 5%:</span>
              <span>$4.5</span>
            </li>
            <li>
              <span>Discount 20%:</span>
              <span>- $18.9</span>
            </li>
            <li>
              <span>Total:</span>
              <span>$75.6</span>
            </li>
          </ul>
          <ul className="btns_group ul_li_block clearfix">
            <li>
              <Link to="/cart">View Cart</Link>
            </li>
            <li>
              <Link to="/checkout">Checkout</Link>
            </li>
          </ul>
        </div>
        <div className={`sidebar_mobile_menu ${activaclass ? "active" : ""}`}>
          <button
            type="button"
            onClick={closetogglesidebar}
            className="close_btn"
          >
            <i className="fal fa-times" />
          </button>
          <div className="msb_widget mb-0 brand_logo text-center">
            <Link to="/">
              <img
                src="assets/images/logo/logo_25_1x.png"
                srcSet="assets/images/logo/logo_25_2x.png 2x"
                alt="logo_not_found"
              />
            </Link>
          </div>
          <div className="msb_widget mobile_menu_list clearfix mb-0">
            <div className="login-btns-div row mx-0 mb-3 justify-content-around">
              <div className="col-6">
                <Link
                  to="/login"
                  className="custom_btn btn-block rounded btn_sm bg_sports_red text-uppercase"
                >
                  login
                </Link>
              </div>
              <div className="col-6">
                <Link
                  to="/register"
                  className="custom_btn rounded btn-block btn_sm bg_black text-uppercase"
                >
                  Sign up
                </Link>
              </div>
            </div>
            {/* <h3 className="title_text mb_15 text-uppercase">
              <i className="far fa-bars mr-2" /> Menu List
            </h3> */}
            <hr />
            <ul className="ul_li_block clearfix">
              <li>
                <Link to="/">Home</Link>
              </li>
              {/* <li>
                <Link to="/categories">Categories</Link>
              </li> */}
              {/* <li>
                <Link to="/shop">Categories</Link>
              </li> */}
              <li className="dropdown">
                <a
                  href="#!"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  Categories
                </a>
                <div className="dropdown-menu">
                  <div className="row mx-0 category-sidebar-row">
                    <div className="col-4">
                      <div className="category-sidebar-card">
                        <Link
                          to={`/categories`}
                          className="category-sidebar-card-body text-center p-0 mb-3"
                        >
                          <img
                            src="assets/images/category/fashion/img_03.jpg"
                            alt="category"
                            className="category-sidebar-img"
                          />
                          <b>All Categories</b>
                        </Link>
                      </div>
                    </div>
                    {categories.map((el, index) => (
                      <div className="col-4">
                        <div className="category-sidebar-card">
                          <Link
                            to={`/productslist?cid=${index}`}
                            className="category-sidebar-card-body text-center p-0 mb-3"
                          >
                            <img
                              src="assets/images/category/fashion/img_03.jpg"
                              alt="category"
                              className="category-sidebar-img"
                            />
                            <b>{el}</b>
                          </Link>
                        </div>
                      </div>
                    ))}
                    {categories.map((el, index) => (
                      <div className="col-4">
                        <div className="category-sidebar-card">
                          <Link
                            to={`/productslist?cid=${index}`}
                            className="category-sidebar-card-body text-center p-0 mb-3"
                          >
                            <img
                              src="assets/images/category/fashion/img_03.jpg"
                              alt="category"
                              className="category-sidebar-img"
                            />
                            <b>{el}</b>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <ul className="dropdown-menu">
                  {" "}
                  <li>
                    <Link to="/categories">All Categories</Link>
                  </li>
                  {categories.map((el, index) => (
                    <li className="">
                      <Link to={`/productslist?cid=${index}`}>{el}</Link>
                    </li>
                  ))}
                </ul> */}
              </li>
              <li>
                <Link to="/aboutus">About Us</Link>
              </li>
              <li>
                <Link to="/contactus">Contact Us</Link>
              </li>
            </ul>
          </div>

          {/* <div className="user_info">
            <h3 className="title_text mb_30 text-uppercase">
              <i className="fas fa-user mr-2" /> User Info
            </h3>
            <div className="profile_info clearfix">
              <div className="user_thumbnail">
                <img
                  src="assets/images/meta/img_01.png"
                  alt="thumbnail_not_found"
                />
              </div>
              <div className="user_content">
                <h4 className="user_name">Jone Doe</h4>
                <span className="user_title">Seller</span>
              </div>
            </div>
            <ul className="settings_options ul_li_block clearfix">
              <li>
                <a href="#!">
                  <i className="fal fa-user-circle" /> Profile
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="fal fa-user-cog" /> Settings
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="fal fa-sign-out-alt" /> Logout
                </a>
              </li>
            </ul>
          </div> */}
        </div>
        <div
          onClick={() => {
            closesidebar();
          }}
          className={`overlay  ${activaclassoverlay ? "active" : ""}`}
        />
      </div>
    </>
  );
};

export default Header;
