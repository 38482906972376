import React from "react";

const NewsLetter = () => {
  return (
    <div className="container-fluid p-0">
      <div className="row no-gutters">
        <div className="col-lg-8">
          <div
            className="sports_newsletter d-flex align-items-center"
            // data-background="assets/images/backgrounds/bg_33.jpg"
          >
            <div className="content_wrap text-center text-white">
              <span
                className="sub_title text-uppercase"
                data-text-color="#ff3f3f"
              >
                Join Our
              </span>
              <h2 className="title_text text-uppercase text-white mb_15">
                Newsletters Now!
              </h2>
              <p className="mb_30">
                Hugo &amp; Marie is an independent artist management firm and
                Creative agency based in New York City. Founded in 2008
              </p>
              <form action="#!">
                <div className="form_item mb-0">
                  <input type="email" name="email" placeholder="Enter email" />
                  <button
                    type="submit"
                    className="submit_btn bg_sports_red text-uppercase"
                  >
                    SUBCRIBE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="sports_feature_video"
            data-background="assets/images/backgrounds/bg_34.jpg"
          >
            <a
              className="play_btn_1"
              href="http://www.youtube.com/watch?v=0O2aH4XLbto"
            >
              <span>
                <i className="fas fa-play" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
