import React, { useState } from "react";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import useScrollTo from "../components/useScrollTo";

const Profile = () => {
  const selector = "categories-section";
  useScrollTo(selector);
  const [categories, setcategories] = useState([
    "Shoes",
    "Tshirt",
    "Jeans",
    "Shirts",
    "Watches",
    "Sports Wear",
    "Suits",
    "Sunglasses",
  ]);

  const breadlinks = [
    {
      url: "/",
      text: "Home",
    },
    {
      url: "/categories",
      text: "Categories",
    },
  ];

  return (
    <div>
      <Header />
      {/* <ScrollToTop /> */}
      <main>
        <section className="profile-section">
          <div className="container">
            <div className="profile-container">
              <div className="profile-cover-main">
                <div className="profile-cover">
                  <img src="assets/images/breadcrumb/bg_14.jpg" alt="cover" />
                </div>
                <div className="profile-cover-bottom">
                  <div className="row mx-0">
                    <div className="col-md-3 col-6">
                      <div className="profile-image">
                        <img
                          src="assets/images/meta/img_01.png"
                          alt="profile"
                        />
                      </div>
                    </div>
                    <div className="col-md-9 col-12">
                      <div className="profile-username py-3">
                        <small>Welcome!</small>
                        <h4>John Doe</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="profile-details-container">
                <div className="row mx-0">
                  <div className="col-3">
                    <div
                      className="nav flex-column nav-pills"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <a
                        className="nav-link active"
                        id="v-pills-home-tab"
                        data-toggle="pill"
                        href="#v-pills-home"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        My Account
                      </a>
                      <a
                        className="nav-link"
                        id="v-pills-profile-tab"
                        data-toggle="pill"
                        href="#v-pills-profile"
                        role="tab"
                        aria-controls="v-pills-profile"
                        aria-selected="false"
                      >
                        My Orders
                      </a>
                      <a
                        className="nav-link"
                        id="v-pills-messages-tab"
                        data-toggle="pill"
                        href="#v-pills-messages"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                      >
                        Account Settings
                      </a>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-home"
                        role="tabpanel"
                        aria-labelledby="v-pills-home-tab"
                      >
                        Account
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-profile"
                        role="tabpanel"
                        aria-labelledby="v-pills-profile-tab"
                      >
                        Orders
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-messages"
                        role="tabpanel"
                        aria-labelledby="v-pills-messages-tab"
                      >
                        Settings
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* product_section - start
			================================================== */}

        {/* product_section - end
			================================================== */}
      </main>
      <Footer />
    </div>
  );
};

export default Profile;
