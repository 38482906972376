import React, { useState } from "react";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import useScrollTo from "../components/useScrollTo";
import NewsLetter from "../components/NewsLetter";

const Categories = () => {
  const selector = "categories-section";
  useScrollTo(selector);
  const [categories, setcategories] = useState([
    "Shoes",
    "Tshirt",
    "Jeans",
    "Shirts",
    "Watches",
    "Sports Wear",
    "Suits",
    "Sunglasses",
  ]);

  const breadlinks = [
    {
      url: "/",
      text: "Home",
    },
    {
      url: "/categories",
      text: "Categories",
    },
  ];

  return (
    <div>
      <Header />
      {/* <ScrollToTop /> */}
      <main>
        <Breadcrumb
          pagename={"Categories"}
          breadcrumbitems={breadlinks}
          backgroundimg={"assets/images/breadcrumb/bg_14.jpg"}
        />
        {/* product_section - start
			================================================== */}
        <section className="category_section sec_ptb_100  clearfix">
          <div className="container">
            <div className="row mt__50 justify-content-center">
              {categories.map((el, index) => (
                <div className="col-lg-3 col-md-4 col-4">
                  <div className="fashion_category_circle">
                    <div className="item_offer bg_fashion_red text-white">
                      <span>50%</span>
                      <span>FLAT</span>
                    </div>
                    <div className="item_image">
                      <img
                        src="assets/images/category/fashion/img_03.jpg"
                        alt="image_not_found"
                      />
                      <Link
                        className="icon_btn bg_fashion_red"
                        to={`/productslist?cid=${index}`}
                      >
                        <i className="fal fa-arrow-right" />
                      </Link>
                    </div>
                    <div className="item_content text-uppercase">
                      <h3 className="item_title">{el}</h3>
                      <span className="item_instock">5 ITEMS</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <NewsLetter />

        {/* <section
          id="categories-section"
          className="product_section sec_ptb_100 clearfix"
        >
          <div className="container">
            <div className="row justify-content-center">
              {categories.map((el, index) => (
                <div
                  key={index}
                  className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6"
                >
                  <div className="sports_product_item">
                    <div className="item_image" data-bg-color="#f5f5f5">
                      <img
                        src="assets/images/shop/sports/img_03.png"
                        alt="image_not_found"
                      />
                      <ul className="product_action_btns ul_li_center clearfix">
                      
                        <li>
                          <Link to={`/productslist?cid=${index}`}>
                            <i className="fas fa-shopping-cart" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="item_content text-uppercase text-white">
                      <h3 className="item_title bg_black text-white mb-0">
                        {el}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section> */}
        {/* product_section - end
			================================================== */}
      </main>
      <Footer />
    </div>
  );
};

export default Categories;
