import React, { useState } from "react";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import ProductCard from "../components/ProductCard";
import { Link, useLocation } from "react-router-dom";
import Pagination from "../components/Pagination";
import useScrollTo from "../components/useScrollTo";

import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const ProductsList = () => {
  const selector = "products-list";
  const [sortOption, setSortOption] = useState("Relevance");
  const [range, setRange] = useState([0, 5000]);
  const [offcanvas, setoffcanvas] = useState(false);
  const [categories, setcategories] = useState([
    "Shoes",
    "Tshirt",
    "Jeans",
    "Shirts",
    "Watches",
    "Sports Wear",
    "Suits",
    "Sunglasses",
  ]);

  // Function to handle the sort option change
  const handleSortOptionChange = (event) => {
    setSortOption(event.target.value);
  };
  const handleSliderChange = (value) => {
    console.log(value, "value range");
    setRange(value);
  }; // Function to handle min price change
  const handleMinPriceChange = (e) => {
    const newMinPrice = parseInt(e.target.value);
    setRange([newMinPrice, range[1]]);
  };

  // Function to handle max price change
  const handleMaxPriceChange = (e) => {
    const newMaxPrice = parseInt(e.target.value);
    setRange([range[0], newMaxPrice]);
  };

  useScrollTo(selector);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cid = searchParams.get("cid");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(6);
  const [totalpages, settotalpages] = useState(60);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [products, setproducts] = useState([
    "Shoes",
    "Tshirt",
    "Jeans",
    "Shirts",
    "Watches",
    "Sports Wear",
    "Suits",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
    "Sunglasses",
  ]);

  const breadlinks = [
    {
      url: "/",
      text: "Home",
    },
    {
      url: "/categories",
      text: "Categories",
    },
    {
      url: "/categories",
      text: "category",
    },
  ];
  return (
    <div className="products-list-main-wrapper">
      <Header />
      {/* <ScrollToTop /> */}
      <main className="products-list-main">
        <Breadcrumb
          pagename={"Product list"}
          breadcrumbitems={breadlinks}
          backgroundimg={"assets/images/breadcrumb/bg_14.jpg"}
        />
        {/* product_section - start
        ================================================== */}
        <section
          id="products-list"
          className="product_section sec_ptb_100 clearfix"
        >
          <div className="container">
            <div className="row mx-0">
              <div className="col-md-3 col-12">
                <div className="offcanvas-body px-3 d-md-block d-none">
                  <h5 className="title">Sort :</h5>
                  <div className="product-short ">
                    <select
                      className="nice-select mb-3"
                      name="sortby"
                      value={sortOption}
                      onChange={handleSortOptionChange}
                    >
                      <option value="Relevance">Relevance</option>
                      <option value="Popularity">Popularity</option>
                      <option value="Low to High">Price (Low to High)</option>
                      <option value="High to Low">Price (High to Low)</option>
                      {/* <option value="ratingHighest">
                            Rating (Highest)
                          </option> */}
                    </select>
                  </div>
                  <div className="shop-sidebar mb-30">
                    <hr />
                    <h5 className="title">FILTER BY PRICE</h5>
                    {/* filter-price-content start */}
                    <div className="filter-price-content">
                      {/* <Slider
                          range
                          min={0}
                          max={5000}
                          value={range}
                          onChange={handleSliderChange}
                        /> */}
                      <RangeSlider
                        min={0}
                        max={5000}
                        value={range}
                        onInput={handleSliderChange}
                      />
                      <form action="#" method="post">
                        <div className="filter-price-wapper">
                          <span>Price:</span>
                          <div className="row py-2">
                            <div className="range-input col-4">
                              <input
                                type="number"
                                className="col-12 p-0"
                                id="min-price"
                                value={range[0]}
                                onChange={handleMinPriceChange}
                              />
                            </div>
                            <span className="col-1">—</span>
                            <div className="range-input col-6">
                              <input
                                type="number"
                                className="col-12 p-0"
                                id="max-price"
                                value={range[1]}
                                onChange={handleMaxPriceChange}
                              />
                            </div>
                            {/* <Link className="add-to-cart-button" to="#">
                            <span>FILTER</span>
                          </Link> */}
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* filter-price-content end */}
                  </div>
                  <div className="shop-sidebar mb-30">
                    <h4 className="title">CATEGORIES</h4>
                    <ul>
                      {categories.map((cat, index) => (
                        <li key={cat._id}>
                          <Link to={`/productslist?cid=${index}`}>
                            {cat}
                            {/* <span>(18)</span> */}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-12 product-list-col">
                <div
                  className={`offcanvas ${offcanvas ? "open" : ""}`}
                  id="offcanvasExample"
                >
                  <div className="offcanvas-header  d-flex align-items-center px-3 pt-2 justify-content-between">
                    <h4>Filters</h4>
                    <button
                      type="button"
                      onClick={() => setoffcanvas(!offcanvas)}
                      className="btn shadow-none"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                  <hr />
                  <div className="offcanvas-body px-3">
                    <h5 className="title">Sort :</h5>
                    <div className="product-short ">
                      <select
                        className="nice-select mb-3"
                        name="sortby"
                        value={sortOption}
                        onChange={handleSortOptionChange}
                      >
                        <option value="Relevance">Relevance</option>
                        <option value="Popularity">Popularity</option>
                        <option value="Low to High">Price (Low to High)</option>
                        <option value="High to Low">Price (High to Low)</option>
                      </select>
                    </div>
                    <div className="shop-sidebar mb-30">
                      <hr />
                      <h5 className="title">FILTER BY PRICE</h5>
                      {/* filter-price-content start */}
                      <div className="filter-price-content">
                        {/* <Slider
                          range
                          min={0}
                          max={5000}
                          value={range}
                          onChange={handleSliderChange}
                        /> */}
                        <RangeSlider
                          min={0}
                          max={5000}
                          value={range}
                          onInput={handleSliderChange}
                        />
                        <form action="#" method="post">
                          <div className="filter-price-wapper">
                            <span>Price:</span>
                            <div className="row py-2">
                              <div className="range-input col-3">
                                <input
                                  type="number"
                                  className="col-12"
                                  id="min-price"
                                  value={range[0]}
                                  onChange={handleMinPriceChange}
                                />
                              </div>
                              <span className="col-1">—</span>
                              <div className="range-input col-6">
                                <input
                                  type="number"
                                  className="col-12"
                                  id="max-price"
                                  value={range[1]}
                                  onChange={handleMaxPriceChange}
                                />
                              </div>
                              {/* <Link className="add-to-cart-button" to="#">
                            <span>FILTER</span>
                          </Link> */}
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* filter-price-content end */}
                    </div>
                    <div className="shop-sidebar mb-30">
                      <h4 className="title">CATEGORIES</h4>
                      <ul>
                        {categories.map((cat, index) => (
                          <li key={cat._id}>
                            <Link to={`/productslist?cid=${index}`}>
                              {cat}
                              {/* <span>(18)</span> */}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="carparts_filetr_bar clearfix">
                  <div className="row align-items-center justify-content-lg-between">
                    <div className="col-12">
                      <p className="result_text text-left m-0">
                        Showing 2130 results
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">
                  {products.map((el, index) => (
                    <div
                      key={index}
                      className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6 product-card-col"
                    >
                      <ProductCard id={index} />
                    </div>
                  ))}
                </div>

                <div className="filters-bottom-fixed d-md-none d-block">
                  <div className="row mx-0">
                    <div className="col-12 sort-collapse p-0">
                      <div className="collapse" id="sortbycollapse">
                        <div className="card card-body border-0 p-0">
                          <div className="card-header d-flex justify-content-between align-items-center ">
                            <h6>
                              <i class="fas fa-sort-alt"></i> Sort by
                            </h6>
                            <button
                              data-toggle="collapse"
                              data-target="#sortbycollapse"
                              className="close"
                              type="button"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="list-group">
                            <button
                              type="button"
                              data-toggle="collapse"
                              data-target="#sortbycollapse"
                              name="Relevance"
                              onClick={(e) => setSortOption(e.target.name)}
                              className={`list-group-item list-group-item-action border-0 ${
                                sortOption === "Relevance" ? "active" : ""
                              }`}
                            >
                              Relevance
                            </button>{" "}
                            <button
                              type="button"
                              data-toggle="collapse"
                              data-target="#sortbycollapse"
                              name="Popularity"
                              onClick={(e) => setSortOption(e.target.name)}
                              className={`list-group-item list-group-item-action border-0 ${
                                sortOption === "Popularity" ? "active" : ""
                              }`}
                            >
                              Popularity
                            </button>
                            <button
                              type="button"
                              data-toggle="collapse"
                              data-target="#sortbycollapse"
                              name="Low to High"
                              onClick={(e) => setSortOption(e.target.name)}
                              className={`list-group-item list-group-item-action border-0 ${
                                sortOption === "Low to High" ? "active" : ""
                              }`}
                            >
                              Low to High
                            </button>
                            <button
                              type="button"
                              data-toggle="collapse"
                              data-target="#sortbycollapse"
                              name="High to Low"
                              onClick={(e) => setSortOption(e.target.name)}
                              className={`list-group-item list-group-item-action border-0 ${
                                sortOption === "High to Low" ? "active" : ""
                              }`}
                            >
                              High to Low
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 p-0">
                      <button
                        data-toggle="collapse"
                        data-target="#sortbycollapse"
                        aria-expanded="false"
                        aria-controls="sortbycollapse"
                        className="btn shadow-none btn-block"
                      >
                        <i class="fas fa-sort-alt"></i> Sort
                      </button>
                    </div>
                    <div className="col-6 p-0">
                      {" "}
                      <button
                        onClick={() => setoffcanvas(!offcanvas)}
                        className="btn shadow-none btn-block"
                      >
                        <i class="far fa-sliders-h"></i> Filter
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-md-block d-none">
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalItems={totalpages}
                    itemsPerPage={itemsPerPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* product_section - end
        ================================================== */}
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default ProductsList;
