import React, { useState, useRef } from "react";

const ImageSlider = () => {
  const [activeTab, setActiveTab] = useState("di_tab_1");
  const [magnify, setMagnify] = useState(false);
  const [selectedslideindex, setselectedslideindex] = useState(0);
  const [lensStyle, setLensStyle] = useState({});
  const [magnifyStyle, setMagnifyStyle] = useState({});
  const magnifyRef = useRef(null);

  console.log(selectedslideindex, "selectedslideindex");

  const images = [
    { id: "di_tab_1", src: "assets/images/shop/sports/img_03.png" },
    { id: "di_tab_2", src: "assets/images/feature/sports/img_03.jpg" },
    { id: "di_tab_3", src: "assets/images/shop/sports/img_03.png" },
    { id: "di_tab_4", src: "assets/images/feature/sports/img_03.jpg" },
    { id: "di_tab_5", src: "assets/images/feature/sports/img_03.jpg" },
    { id: "di_tab_6", src: "assets/images/feature/sports/img_03.jpg" },
    { id: "di_tab_7", src: "assets/images/feature/sports/img_03.jpg" },
    { id: "di_tab_8", src: "assets/images/feature/sports/img_03.jpg" },
  ];

  const handleMouseEnter = () => {
    setMagnify(true);
  };

  const handleMouseLeave = () => {
    setMagnify(false);
    setLensStyle({});
    setMagnifyStyle({});
  };

  const handleMouseMove = (e, src) => {
    const rect = magnifyRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const xPercent = Math.round((x / rect.width) * 100);
    const yPercent = 100 - Math.abs(Math.round((y / rect.height) * 100));

    const lensSize = 400; // Size of the lens
    const lensX = x - 50;
    const lensY = Math.round(y) + 350;

    console.log(lensX, "lensX", x);
    console.log(lensY, "lensY", Math.round(y), e.clientY, rect.top);

    setLensStyle({
      top: `${lensY}px`,
      left: `${lensX}px`,
      width: `100px`,
      height: `100px`,
      backgroundImage: `url(${src})`,
      backgroundPosition: `${xPercent}% ${yPercent}%`,
      backgroundSize: `250%`,
    });

    setMagnifyStyle({
      backgroundImage: `url(${src})`,
      backgroundPosition: `${xPercent}% ${yPercent}%`,
    });
  };

  const handlemobsliderchange = (ind) => {
    setselectedslideindex(ind);
  };

  return (
    <>
      <div className="details_image_tab">
        <div className="tab-content mb_30">
          {images.map((image, index) => (
            <div
              key={image.id}
              id={image.id}
              className={`tab-pane ${
                activeTab === image.id ? "active" : "fade"
              }`}
            >
              <div
                className="details_image position-relative d-md-block d-none"
                ref={magnifyRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseMove={(e) => handleMouseMove(e, image.src)}
              >
                <img
                  className="product-active-image"
                  src={image.src}
                  alt="img"
                />
                {magnify && (
                  <>
                    <div className="lens " style={lensStyle}></div>
                    <div className="magnify-image " style={magnifyStyle}></div>
                  </>
                )}
              </div>
              <div
                className="details_image position-relative d-md-none d-block"
                // ref={magnifyRef}
                onClick={() => handlemobsliderchange(index)}
                data-toggle="modal"
                data-target="#productimages"
              >
                <img
                  className="product-active-image"
                  src={image.src}
                  alt="img"
                />
              </div>
            </div>
          ))}
        </div>
        <ul
          className="details_image_nav flex-nowrap nav ul_li_center"
          role="tablist"
        >
          {images.map((image) => (
            <li key={image.id}>
              <a
                className={activeTab === image.id ? "active" : ""}
                onClick={() => setActiveTab(image.id)}
                data-toggle="tab"
                href={`#${image.id}`}
              >
                <img src={image.src} alt="image_not_found" />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div>
        {/* Button trigger modal */}
   
        {/* Modal */}
        <div
          className="modal p-0 m-0 fade"
          id="productimages"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog m-0">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="modal-body p-0">
                <div className="product-img-slider">
                  <div
                    id="productimg-carousel"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <div className="product-carousel-indicators">
                      {/* <div className="product-indicators-box"> */}
                      {images.map((el, index) => (
                        <div
                          key={index}
                          onClick={() => handlemobsliderchange(index)}
                          data-target="#productimg-carousel"
                          data-slide-to={index}
                          className={`indicator-item ${
                            index === selectedslideindex ? "active" : ""
                          }`}
                        >
                          <img src={`${el.src}`} alt="" />
                        </div>
                      ))}
                      {/* </div> */}
                    </div>
                    <div className="carousel-inner ">
                      {images.map((el, index) => {
                        return (
                          <div
                            key={index}
                            className={`carousel-item ${
                              index === selectedslideindex ? "active" : ""
                            }`}
                          >
                            <div className="product-carousel-img">
                              <img src={`${el.src}`} alt="product" />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <a
                      className="carousel-control-prev"
                      href="#productimg-carousel"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#productimg-carousel"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
