import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";
import { Link } from "react-router-dom";
import useScrollTo from "../../components/useScrollTo";

const CheckoutTwo = () => {
  useScrollTo();
  const breadlinks = [
    {
      url: "/",
      text: "Home",
    },
    {
      url: "/checkout",
      text: "Checkout",
    },
  ];
  return (
    <div>
      <Header />
      <main>
        {" "}
        <Breadcrumb
          pagename={"Checkout"}
          breadcrumbitems={breadlinks}
          backgroundimg={"assets/images/breadcrumb/bg_14.jpg"}
        />
        <section className="checkout_section sec_ptb_140 clearfix">
          <div className="container">
            <ul className="checkout_step ul_li clearfix">
              <li className="activated">
                <Link to="/checkout">
                  <span>01.</span> Shopping Cart
                </Link>
              </li>
              <li className="active">
                <Link to="/checkout-two">
                  <span>02.</span> Checkout
                </Link>
              </li>
              <li>
                <Link to="/checkout-three">
                  <span>03.</span> Order Completed
                </Link>
              </li>
            </ul>
            <div className="row">
              <div className="col-lg-6">
                <div className="checkout_collapse_content">
                  <div className="wrap_heade">
                    <p className="mb-0">
                      Returning customer?{" "}
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#loginform_collapse"
                        aria-expanded="false"
                        role="button"
                      >
                        Click here to login
                      </a>
                    </p>
                  </div>
                  <div
                    id="loginform_collapse"
                    className="collapse_form_wrap collapse"
                  >
                    <div className="card-body">
                      <form action="#">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form_item">
                              <input
                                type="email"
                                name="email"
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form_item">
                              <input
                                type="password"
                                name="password"
                                placeholder="Password"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="login_button">
                          <div className="checkbox_item">
                            <label htmlFor="remember_checkbox">
                              <input id="remember_checkbox" type="checkbox" />{" "}
                              Remember me
                            </label>
                          </div>
                          <button
                            type="submit"
                            className="custom_btn bg_default_red"
                          >
                            Login Now
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="checkout_collapse_content">
                  <div className="wrap_heade">
                    <p className="mb-0">
                      <i className="ti-info-alt" />
                      Have a coupon?{" "}
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#coupon_collapse"
                        aria-expanded="false"
                      >
                        Click here to enter your code
                      </a>
                    </p>
                  </div>
                  <div
                    id="coupon_collapse"
                    className="collapse_form_wrap collapse"
                  >
                    <div className="card-body">
                      <form action="#">
                        <div className="form_item">
                          <input
                            type="text"
                            name="coupon"
                            placeholder="Coupon Code"
                          />
                        </div>
                        <button
                          type="submit"
                          className="custom_btn bg_default_red"
                        >
                          Apply coupon
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="billing_form mb_50">
              <h3 className="form_title mb_30">Billing details</h3>
              <form action="#">
                <div className="form_wrap">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form_item">
                        <span className="input_title">
                          First Name<sup>*</sup>
                        </span>
                        <input type="text" name="firstname" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form_item">
                        <span className="input_title">
                          Last Name<sup>*</sup>
                        </span>
                        <input type="text" name="lastname" />
                      </div>
                    </div>
                  </div>
                  <div className="form_item">
                    <span className="input_title">
                      Company Name<sup>*</sup>
                    </span>
                    <input type="text" name="company" />
                  </div>
                  <div className="option_select">
                    <span className="input_title">
                      Country<sup>*</sup>
                    </span>
                    <select name="country">
                      <option value="USA" selected>
                        United States
                      </option>
                      <option value="USA">United States</option>
                      <option value="USA">United States</option>
                      <option value="USA">United States</option>
                    </select>
                  </div>
                  <div className="form_item">
                    <span className="input_title">
                      Address<sup>*</sup>
                    </span>
                    <input
                      type="text"
                      name="address"
                      placeholder="House number and street name"
                    />
                  </div>
                  <div className="form_item">
                    <span className="input_title">
                      Town/City<sup>*</sup>
                    </span>
                    <input type="text" name="city" />
                  </div>
                  <div className="form_item">
                    <span className="input_title">
                      County<sup>*</sup>
                    </span>
                    <input type="text" name="county" />
                  </div>
                  <div className="form_item">
                    <span className="input_title">
                      Postcode / Zip<sup>*</sup>
                    </span>
                    <input type="text" name="postcode" />
                  </div>
                  <div className="form_item">
                    <span className="input_title">
                      Phone<sup>*</sup>
                    </span>
                    <input type="tel" name="phone" />
                  </div>
                  <div className="form_item">
                    <span className="input_title">
                      Email Address<sup>*</sup>
                    </span>
                    <input type="email" name="email" />
                  </div>
                  <div className="checkbox_item">
                    <label htmlFor="account_create_checkbox">
                      <input id="account_create_checkbox" type="checkbox" />{" "}
                      Create an account?
                    </label>
                  </div>
                  <hr />
                  <div className="checkbox_item mb_30">
                    <label htmlFor="ship_address_checkbox">
                      <input id="ship_address_checkbox" type="checkbox" /> Ship
                      to a different address?
                    </label>
                  </div>
                  <div className="form_item mb-0">
                    <span className="input_title">
                      Order notes<sup>*</sup>
                    </span>
                    <textarea
                      name="note"
                      placeholder="Note about your order, eg. special notes fordelivery."
                      defaultValue={""}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="billing_form">
              <h3 className="form_title mb_30">Your order</h3>
              <form action="#">
                <div className="form_wrap">
                  <div className="checkout_table">
                    <table className="table text-center mb_50">
                      <thead className="text-uppercase text-uppercase">
                        <tr>
                          <th>Product Name</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="cart_product">
                              <div className="item_image">
                                <img
                                  src="assets/images/cart/img_04.jpg"
                                  alt="image_not_found"
                                />
                              </div>
                              <div className="item_content">
                                <h4 className="item_title mb-0">
                                  Top Curabitur Lectus
                                </h4>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="price_text">$69.00</span>
                          </td>
                          <td>
                            <span className="quantity_text">2</span>
                          </td>
                          <td>
                            <span className="total_price">$138.00</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="cart_product">
                              <div className="item_image">
                                <img
                                  src="assets/images/cart/img_04.jpg"
                                  alt="image_not_found"
                                />
                              </div>
                              <div className="item_content">
                                <h4 className="item_title mb-0">
                                  Dress Lobortis Laculis
                                </h4>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="price_text">$69.00</span>
                          </td>
                          <td>
                            <span className="quantity_text">2</span>
                          </td>
                          <td>
                            <span className="total_price">$138.00</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="cart_product">
                              <div className="item_image">
                                <img
                                  src="assets/images/cart/img_04.jpg"
                                  alt="image_not_found"
                                />
                              </div>
                              <div className="item_content">
                                <h4 className="item_title mb-0">
                                  Boot Curabitur Lectus
                                </h4>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="price_text">$69.00</span>
                          </td>
                          <td>
                            <span className="quantity_text">2</span>
                          </td>
                          <td>
                            <span className="total_price">$138.00</span>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td>
                            <span className="subtotal_text">Subtotal</span>
                          </td>
                          <td>
                            <span className="total_price">$414.00</span>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td>
                            <span className="subtotal_text">Shipping</span>
                          </td>
                          <td className="text-left">
                            <div className="checkbox_item mb_15">
                              <label htmlFor="shipping_checkbox">
                                <input
                                  id="shipping_checkbox"
                                  type="checkbox"
                                  defaultChecked
                                />{" "}
                                Free Shipping
                              </label>
                            </div>
                            <div className="checkbox_item mb_15">
                              <label htmlFor="flatrate_checkbox">
                                <input id="flatrate_checkbox" type="checkbox" />{" "}
                                Flat rate: $15.00
                              </label>
                            </div>
                            <div className="checkbox_item">
                              <label htmlFor="localpickup_checkbox">
                                <input
                                  id="localpickup_checkbox"
                                  type="checkbox"
                                />{" "}
                                Local Pickup: $8.00
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            <span className="subtotal_text">TOTAL</span>
                          </td>
                          <td />
                          <td />
                          <td>
                            <span className="total_price">$135.00</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="billing_payment_mathod">
                    <ul className="ul_li_block clearfix">
                      <li>
                        <div className="checkbox_item mb_15 pl-0">
                          <label htmlFor="bank_transfer_checkbox">
                            <input
                              id="bank_transfer_checkbox"
                              type="checkbox"
                              defaultChecked
                            />{" "}
                            Direct Bank Transfer
                          </label>
                        </div>
                        <p className="mb-0">
                          Make your payment directly into our bank account.
                          Please use your Order ID as the payment reference.
                          Your order will not be shipped until the funds have
                          cleared in our account.
                        </p>
                      </li>
                      <li>
                        <div className="checkbox_item mb-0 pl-0">
                          <label htmlFor="check_payments">
                            <input id="check_payments" type="checkbox" />
                            Check Payments
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox_item mb-0 pl-0">
                          <label htmlFor="cash_delivery">
                            <input id="cash_delivery" type="checkbox" /> Cash On
                            Delivery
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox_item mb-0 pl-0">
                          <label htmlFor="paypal_checkbox">
                            <input id="paypal_checkbox" type="checkbox" />{" "}
                            Paypal{" "}
                            <a href="#!">
                              <img
                                className="paypal_image"
                                src="assets/images/payment_methods_03.png"
                                alt="image_not_found"
                              />
                            </a>
                          </label>
                        </div>
                      </li>
                    </ul>
                    <button type="submit" className="custom_btn bg_default_red">
                      PLACE ORDER
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default CheckoutTwo;
