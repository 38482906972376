// import logo from "./logo.svg";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import Categories from "./pages/Categories";
import Productpage from "./pages/Productpage";
import ProductsList from "./pages/ProductsList";
import Checkout from "./pages/checkout/Checkout";
import CheckoutTwo from "./pages/checkout/CheckoutTwo";
import CheckoutThree from "./pages/checkout/CheckoutThree";
import Cartpage from "./pages/Cartpage";
import ContactUs from "./pages/ContactUs";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import Resetpassword from "./pages/Resetpassword";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Aboutus from "./pages/Aboutus";
import Profile from "./pages/Profile";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/product" element={<Productpage />} />
        <Route path="/productslist" element={<ProductsList />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/checkout-two" element={<CheckoutTwo />} />
        <Route path="/checkout-three" element={<CheckoutThree />} />
        <Route path="/cart" element={<Cartpage />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<Resetpassword />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />

        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/profile" element={<Profile />} />
        {/* <Route path="/superlogin" element={<Loginpage />} />
     
        <Route path="/dashboard" element={<Protect Component={Home} />} />
       
        /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
