import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import { Link } from "react-router-dom";
import useScrollTo from "../components/useScrollTo";
const ContactUs = () => {
  useScrollTo();
  const breadlinks = [
    {
      url: "/",
      text: "Home",
    },
    {
      url: "/contactus",
      text: "Contact Us",
    },
  ];
  return (
    <div>
      <Header />
      <main>
        {" "}
        <Breadcrumb
          pagename={"Contact Us"}
          breadcrumbitems={breadlinks}
          backgroundimg={"assets/images/breadcrumb/bg_14.jpg"}
        />
        <section className="main_contact_section sec_ptb_100 clearfix">
          <div className="container">
            <div className="row justify-content-lg-between">
              <div className="col-lg-5">
                <div className="main_contact_content">
                  <h3 className="title_text mb_15">Get In Touch</h3>
                  <p className="mb_50">
                    If you are interested in working with us, please get in
                    touch.
                  </p>
                  <ul className="main_contact_info ul_li_block clearfix">
                    <li>
                      <span className="icon">
                        <i className="fal fa-map-marked-alt" />
                      </span>
                      <p className="mb-0">
                        75 South Park Avenue, Melbourne, Australia
                      </p>
                    </li>
                    <li>
                      <span className="icon">
                        <i className="fal fa-phone-volume" />
                      </span>
                      <p className="mb-0">8 800 567.890.11 - Central Office</p>
                    </li>
                    <li>
                      <span className="icon">
                        <i className="fal fa-paper-plane" />
                      </span>
                      <p className="mb-0">Jthemes@gmail.com</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="main_contact_form">
                  <h3 className="title_text mb_30">FeedBack</h3>
                  <form action="#">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div className="form_item">
                          <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div className="form_item">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="form_item">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form_item">
                      <textarea
                        name="message"
                        placeholder="Your Message"
                        defaultValue={""}
                      />
                    </div>
                    <button
                      type="submit"
                      className="custom_btn bg_default_red text-uppercase"
                    >
                      view projects
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ContactUs;
