import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import { Link } from "react-router-dom";
import useScrollTo from "../components/useScrollTo";

const Login = () => {
  useScrollTo();
  const breadlinks = [
    {
      url: "/",
      text: "Home",
    },
    {
      url: "/contactus",
      text: "Contact Us",
    },
  ];
  return (
    <div>
      <Header />
      <main className="login-main">
        <section
          className="register_section sec_ptb_140 has_overlay parallaxie clearfix"
          data-background="assets/images/backgrounds/bg_22.jpg"
        >
          <div className="overlay" data-bg-color="rgba(55, 55, 55, 0.75)" />
          <div className="container">
            <div
              className="reg_form_wrap login_form d-flex justify-content-center align-items-center"
              //   data-background="assets/images/reg_bg_01.png"
            >
              <form action="#">
                <div className="reg_form">
                  <h2 className="form_title text-uppercase text-center">
                    Login
                  </h2>
                  <div className="form_item">
                    <input
                      id="username_input"
                      type="text"
                      name="username"
                      placeholder="username"
                    />
                    <label htmlFor="username_input">
                      <i className="fal fa-user" />
                    </label>
                  </div>
                  <div className="form_item">
                    <input
                      id="password_input"
                      type="password"
                      name="password"
                      placeholder="password"
                    />
                    <label htmlFor="password_input">
                      <i className="fal fa-unlock-alt" />
                    </label>
                  </div>
                  <Link
                    className="forget_pass text-uppercase mb_30"
                    to="/forgotpassword"
                  >
                    Forgot password?
                  </Link>
                  <button
                    type="submit"
                    className="custom_btn bg_default_red text-uppercase mb_50"
                  >
                    Login
                  </button>
                  {/* <div className="social_wrap mb_100">
                    <h4 className="small_title_text mb_15 text-center text-uppercase">
                      Or Login With
                    </h4>
                    <ul className="circle_social_links ul_li_center clearfix">
                      <li>
                        <a data-bg-color="#3b5998" href="#!">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a data-bg-color="#1da1f2" href="#!">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a data-bg-color="#ea4335" href="#!">
                          <i className="fab fa-google-plus-g" />
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="create_account text-center">
                    <h4 className="small_title_text text-center text-uppercase">
                      Have no account yet?
                    </h4>
                    <Link
                      className="create_account_btn text-uppercase"
                      to="/register"
                    >
                      Sign Up
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Login;
