import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import { Link } from "react-router-dom";
import useScrollTo from "../components/useScrollTo";

const Register = () => {
  useScrollTo();
  const breadlinks = [
    {
      url: "/",
      text: "Home",
    },
    {
      url: "/contactus",
      text: "Contact Us",
    },
  ];
  return (
    <div>
      <Header />
      <main className="login-main">
        <section
          className="register_section sec_ptb_140 parallaxie clearfix"
          data-background="assets/images/backgrounds/bg_23.jpg"
        >
          <div className="container">
            <div
              className="reg_form_wrap signup_form d-flex justify-content-center align-items-center"
              //   data-background="assets/images/reg_bg_02.png"
            >
              <form action="#">
                <div className="reg_form row mx-0">
                  <h2 className="form_title text-uppercase col-12 text-center">
                    Register
                  </h2>
                  <div className="form_item col-md-6 col-12">
                    <input
                      type="text"
                      name="firstname"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="form_item col-md-6 col-12">
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Last Name"
                    />
                  </div>
                  {/* <div className="form_item col-md-6 col-12">
                    <input
                      type="text"
                      name="company"
                      placeholder="Company Name"
                    />
                  </div> */}
                  <div className="form_item col-md-6 col-12">
                    <input type="email" name="email" placeholder="Email" />
                  </div>
                  <div className="form_item col-md-6 col-12">
                    <input type="tel" name="phone" placeholder="phone" />
                  </div>
                  <div className="form_item col-md-6 col-12">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="form_item col-md-6 col-12">
                    <input
                      type="password"
                      name="password"
                      placeholder="Confirm Password"
                    />
                  </div>
                  <div className="checkbox_item mb_30 col-12">
                    <label htmlFor="agree_checkbox">
                      <input id="agree_checkbox" type="checkbox" /> I agree to
                      the Terms of User
                    </label>
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="custom_btn bg_default_red text-uppercase mb_50"
                    >
                      Create Account
                    </button>
                  </div>
                  <div className="create_account text-center col-12">
                    <h4 className="small_title_text text-center text-uppercase">
                      Have not account yet?
                    </h4>
                    <Link
                      className="create_account_btn text-uppercase"
                      to="/login"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Register;
